.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.play-area{
  /*background-color: red;*/
  display: flex;
  padding: 20px;
  justify-content: center;
}


.stack{
  margin: 0px 40px;
  width: 250px;
  padding: 20px 5px;
}

.stack .stack-title{
  font-size: 20px;
  font-weight: bold;
}

.stack .stack-node{
  margin: 5px 5px;
  padding: 5px 0 ;

  font-weight: bold;
}

.stack-open{
  background-color: #faeca5;
}

.stack .stack-node-open{
  background-color: #ead87e;
  /*background-color: #81dde5;*/
}

.stack-close{
  background-color: #b8fab8;
}
.stack-node-close{
  background-color: #8bee8b;
}

nav{
  display: flex;
  padding-top: 30px;
  margin: 0;
  justify-content: center;
  align-items: center;
}

nav .title{
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

nav h5{
  margin-bottom: 5px;
}

nav p{
  margin-top: 0px;
}

nav img{
  height: 90px;
}

.active-row{
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 0 15px;
}

.active-cell{
  background-color: #e0fc9e;
  padding: 5px 0;
  width: 300px;
  border-radius: 5px;
}

form  .form-group{
  display: flex;
  width: 450px;
  align-items: center;
  margin: auto;
}

table{
  position: relative;
}

table p{
  z-index: 100;
  position: absolute;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

#d-nalanda{
  bottom: -50px;
  right: 20px;
}

#d-techm{
    bottom: 160px;
    left: -70px;
}

#d-prem{
  bottom: -50px;
  left: 50%;
}

#d-puri{
  top: -10px;
  left: 60%;
}

#d-main{
  height: 65px;
  width: auto;
  top: 35%;
  left: 70%;
}

#d-main img{
  height: 100%;
  width: auto;
}

#d-gate{
  height: 65px;
  width: auto;
  top: -35px;
  left: 53%;
}

#d-gate img{
  height: 100%;
}