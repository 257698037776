.node {
  width: 30px;
  height: 30px;
  outline: 1px solid rgb(175, 216, 248);
  display: inline-block;
  position: relative;
  z-index: 0;
}

.node-header{
  font-weight: bold;
  background-color: lightgray;
}

.node p{
  user-select: none;
  /*position: relative;*/
  /*z-index: -1;*/
}

.node-finish {
  background-color: red;
}

.node-start {
  background-color: green;
}

.open-list-node{
  background-color: #faeca5;
}

.close-list-node{
  background-color: #8bee8b;
}

.active-list-node{
  background-color: #81dde5;
}

.path-node{
  background-color: #46bcee;
}

.node-visited {
  animation-name: visitedAnimation;
  animation-duration: 0.1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes visitedAnimation {
  0% {
    /*transform: scale(0.3);*/
    /*background-color: rgba(0, 0, 66, 0.75);*/
    /*border-radius: 100%;*/
  }

  50% {
    /*background-color: rgba(217, 17, 187, 0.75);*/
  }

  75% {
    /*transform: scale(1.2);*/
    /*background-color: rgba(34, 17, 217, 0.75);*/
  }

  100% {
    /*transform: scale(1);*/
    background-color: rgba(198, 208, 206, 0.75);
  }
}

.node-wall {
  background-color: rgb(12, 53, 71);
}

.node-shortest-path {
  animation-name: shortestPath;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes shortestPath {
  0% {
    transform: scale(0.6);
    background-color: rgb(255, 254, 106);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(255, 254, 106);
  }

  100% {
    transform: scale(1);
    background-color: rgb(255, 254, 106);
  }
}
